/**
 * Handle display of the navigation and dropdowns on mobile. This also detects which browser is being used and, if it's
 * Safari, the navigation is modified to optimise display on Apple mobile devices
 */
export class Navigation {
    $navigation; // The main navigation element
    $mobileMenuToggle; // Mobile menu toggle button element
    browser = {}; // Stores information about the browser
    css = {
        dropdown : 'has-dropdown', // Marks a navigation item as having a dropdown menu
        hide : 'invisible', // Should hide the mobile navigation (on scroll down)
        navigation : 'navigation-end', // Marks the area where the menu items are stored
        safariBar : 'safari-overflow-bar', // Applies styles to countreract forced menus that get displayed on iPhones
        safariPadding : 'safari-padding', // Applies styles to countreract forced padding that gets displayed on iPhones
        toggle : 'menu-toggle', // Marks the button on mobile navigation that toggles the display of all menu items
    };
    id = 'navigation-menu'; // The id of the main navigation element
    scrollPositionOnStart = 0; // Track the current scroll position

    constructor() {
        this.$navigation = $(`#${this.id}`);
        this.$mobileMenuToggle = this.$navigation.find(`.${this.css.toggle}`);
        this.browser = this.getBrowserSpecs();
    }

    /**
     * @return {Navigation}
     */
    setSafariPadding() {
        if (this.browser.name == 'Safari') {
            this.$navigation.addClass(this.css.safariPadding);
        }

        return this;
    }

    /**
     * Display the navigation for desktop or mobile. For mobile, the scroll direction and position define whether it is
     * visible or not. For desktop it should always be visible
     * 
     * @param {Object}
     */
    display($window) {
        // There is an in-built browser menu that can't be disabled - this menu creates an untappable area that breaks
        // our mobile navigation and this needs to be taken into consideration when displaying it
        if (this.browser.name == 'Safari') {
            const bottom = this.$navigation[0].getBoundingClientRect().bottom;

            if (Math.floor(bottom) > $window.height()) {
                this.$navigation.addClass(this.css.safariBar);
            } else {
                this.$navigation.removeClass(this.css.safariBar);
            }
        }

        // The navigation is split into 2 styles - mobile and desktop. If mobile, the navigation is at the bottom and
        // the logo/banner are at the top
        if ($window.width() < 1024) {
            // Check to see in which direction the user is scrolling
            if (this.scrollPositionOnStart === 0 || this.scrollPositionOnStart >= $window.scrollTop()) { // Down
                this.$navigation.removeClass(this.css.hide);
            } else if (this.scrollPositionOnStart < $window.scrollTop()) { // Up
                this.$navigation.addClass(this.css.hide);
            }

            this.scrollPositionOnStart = $window.scrollTop();

            // Prevent negative values
            if (this.scrollPositionOnStart < 0) {
                this.scrollPositionOnStart = 0;
            }
        }
    }

    /**
     * Toggle navigation display on mobile devices
     */
    listenToMenu() {
        this.$mobileMenuToggle.click(() => {
            import('./components/websites/navigation').then((displayNavOnClick) => {
                displayNavOnClick.popup(this.$mobileMenuToggle);
            }).catch((error) => console.error(error));
        });
    }

    /**
     * Toggle display of dropdown menus on mobile devices
     */
    listenToDropdown() {
        $(`#${this.css.navigation}`).find(`.${this.css.dropdown}`).click(function() {
            import('./components/websites/navigation').then((displayNavOnClick) => {
                displayNavOnClick.dropdown($(this));
            }).catch((error) => console.error(error));
        });
    }

    /**
     * Detect which browser is being used
     * 
     * @return {Object}
     */
    getBrowserSpecs() {
        const ua = navigator.userAgent;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [], tem;

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

            return {
                name : 'IE',
                version : (tem[1] || '')
            };
        } else if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);

            if (tem !== null) {
                return {
                    name : tem[1].replace('OPR', 'Opera'),
                    version : tem[2]
                };
            }
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

        if ((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);

        return {
            name : M[0],
            version : M[1]
        };
    }
}