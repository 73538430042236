import 'styles/common.styl';
import 'jquery-debounce-throttle';
import 'lazysizes';
import { Navigation } from 'scripts/navigation.service';

window.onload = () => {
    const navigation = new Navigation(),
        $window = $(window)
        ;

    $window.scroll($.throttle(() => {
        navigation.display($window);
    }, 150));

    if ($window.width() < 1024) {
        navigation.listenToDropdown();
    }

    navigation.display($window);
    navigation.listenToMenu();
};